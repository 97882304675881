import { useAuthStore } from "~/auth/stores/auth"

export const useAccessControl = () => {
  const auth = useAuthStore()
  const roles = computed(() => auth.user?.roles)
  const permissions = computed(() => auth.user?.permissions)

  const userHasRole = (role: string | string[]) => {
    return hasRole(role, roles.value as string[])
  }

  const hasRole = (role: string | string[], userRoles: string[]) => {
    if (Array.isArray(role)) {
      for (let i = 0; i < role.length; i++) {
        if (userRoles?.includes(role[i])) {
          return true
        }
      }
    } else {
      return userRoles ? userRoles?.includes(role) : false
    }
  }

  const userCan = (permission: string | string[]): boolean => {
    if (superAdmin.value) return true
    if (Array.isArray(permission)) {
      for (let i = 0; i < permission.length; i++) {
        if (permissions?.value?.includes(permission[i])) {
          return true
        }
      }
    } else {
      return permissions.value
        ? permissions?.value?.includes(permission)
        : false
    }
    return false
  }

  const superAdmin = computed(() => userHasRole("Super Admin"))
  const director = computed(
    () => userHasRole("Director") || userHasRole("Managing Director"),
  )
  const marketing = computed(
    () =>
      userHasRole("Supervisor Marketing") ||
      userHasRole("Manager Marketing") ||
      userHasRole("Senior Marketing"),
  )
  const finance = computed(
    () =>
      userHasRole("Supervisor Finance") ||
      userHasRole("Manager Finance") ||
      userHasRole("Senior Finance"),
  )

  return {
    userHasRole,
    hasRole,
    userCan,
    superAdmin,
    director,
    marketing,
    finance,
  }
}
